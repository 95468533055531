/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ImportDocumentStatus {
    QUEUED = 'queued',
    EXTRACTING = 'extracting',
    ML_PROCESSING = 'mlProcessing',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed',
    DELETED = 'deleted',
}
