import { createColumnHelper } from '@tanstack/vue-table'
import type { IImportDocument } from '@workflow'

export const useProcessingColumns = () => {
  const columnHelper = createColumnHelper<IImportDocument>()

  return computed(() => [
    columnHelper.accessor('filename', {
      header: 'File Name',
      size: 200,
    }),
    columnHelper.accessor('status', {
      header: 'Status',
    }),
    columnHelper.accessor('error', {
      header: 'Reason',
      size: 300,
    }),

    columnHelper.display({
      id: 'actions',
      size: 80,
    }),
  ])
}
