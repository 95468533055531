<script setup lang="ts">
import { useProcessingColumns } from './composables/useProcessingColumns'
import {
  ImportDocumentStatus,
  useDeleteImportDocument,
  type DocumentForModal,
  type IImportDocument,
  type IImportDocumentToReview,
} from '@workflow'

import { useUploadStore } from '~/stores/upload'
import type { TanstackTableColumn } from '@ui/components/TanstackTable'
import type { IPagination } from '@shared/composables/usePagination'
import type {
  ImportDocumentDto,
  ImportDocumentToReviewDto,
} from '~/data-services/gen'

interface Props {
  title: string
  data: IImportDocument[]
  loading?: boolean
  total?: number
  type: 'toReview' | 'processing' | 'failed'
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  total: 0,
})

const selectedDocuments = ref<IImportDocument[]>([])
const selectedRows = defineModel<
  (ImportDocumentDto | ImportDocumentToReviewDto)[]
>('selectedRows', {
  default: () => [],
})
const pagination = defineModel<IPagination>('pagination', {
  default: () => ({ page: 1, pageSize: 25 }),
})

function deleteTitleTooltip(filename?: string): string {
  return `Delete: "${filename}"`
}

function getStatus(data: any): {
  tooltip: string
  text: string
} {
  const status: ImportDocumentStatus = data.status
  switch (status) {
    case ImportDocumentStatus.QUEUED:
      return {
        tooltip: 'Waiting machine learing to start extracting',
        text: 'Queued',
      }
    case ImportDocumentStatus.EXTRACTING:
      return { tooltip: 'Extracting', text: 'Extracting' }
    case ImportDocumentStatus.FAILED:
      return {
        tooltip: data.error,
        text:
          data.error === 'Textract Processing Failed: corrupted'
            ? 'Failed: File corrupted'
            : 'Failed',
      }
    case ImportDocumentStatus.ML_PROCESSING:
      return {
        tooltip: 'Machine Learning is processing the file',
        text: 'Machine Learning processing',
      }
    case ImportDocumentStatus.SUCCEEDED:
      return { tooltip: 'Succeeded', text: 'Succeeded' }
    default:
      return { tooltip: 'N/A', text: '' }
  }
}

const deleteDocuments = computed<DocumentForModal[]>(() => {
  return selectedDocuments.value.map((doc) => ({
    id: doc.id,
    filename: doc.filename,
    name: doc.lease?.name,
  }))
})

const divisionId = getCurrentDivisionId()
const openDeleteConfirmation = useDeleteImportDocument(
  divisionId,
  deleteDocuments,
  'workflow',
  () => {
    selectedRows.value = []
  },
)

function showModalArchive(data: IImportDocument): void {
  selectedDocuments.value = [data]
  openDeleteConfirmation()
}

const uploadStore = useUploadStore()

const files = computed(() => {
  if (props.type === 'processing') {
    return uploadStore.files.uploading.filter(
      (file) => file.company.id === divisionId,
    )
  }

  if (props.type === 'failed') {
    return uploadStore.files.error.filter(
      (file) => file.company.id === divisionId,
    )
  }

  return undefined
})

const columns = useProcessingColumns()
</script>

<template>
  <DataTable
    v-model:pagination="pagination"
    v-model:selection="selectedRows"
    :total-records="total"
    :columns="columns"
    :data="data"
    :loading="loading"
  >
    <template #empty>
      <div class="my-8 flex flex-col items-center justify-center">
        <h1>No documents are being processed</h1>
        <Icon name="no-lease" class="my-8 text-8xl text-gray-200" />
        <p>Upload a document</p>
      </div>
    </template>
    <template
      #item-status="{ item: document }: TanstackTableColumn<IImportDocument>"
    >
      <div class="flex w-full">
        <VTooltip
          class="inline-flex"
          :delay="10"
          placement="top"
          :distance="12"
          :auto-hide="true"
        >
          <span class="inline-flex">
            <span class="flex w-8 self-center">
              <Spinner
                v-if="document.status !== ImportDocumentStatus.FAILED"
                size="sm"
                class="fill-primary -mt-0.5 mr-2 flex w-full justify-center self-center text-xs text-gray-500"
              />
              <Icon
                v-else-if="document.status === ImportDocumentStatus.FAILED"
                size="18"
                class="mr-1 text-xl text-red-500"
                name="documents-warning"
                filled
              />
            </span>
            <span
              class="self-center text-sm"
              :class="{
                'text-red-500': document.status === ImportDocumentStatus.FAILED,
              }"
            >
              {{ getStatus(document).text }}
            </span>
          </span>

          <template #popper>
            <div class="text-xs">{{ getStatus(document).tooltip }}</div>
          </template>
        </VTooltip>
      </div>
    </template>

    <template
      #item-filename="{ item: document }: TanstackTableColumn<IImportDocument>"
    >
      <span class="flex grow text-sm">
        {{ document.filename || '-' }}
      </span>
    </template>

    <template
      #item-actions="{ item: document }: TanstackTableColumn<IImportDocument>"
    >
      <Tooltip
        placement="left"
        :content="deleteTitleTooltip(document.filename)"
        class="bg-red-50 font-medium text-red-500"
      >
        <div class="delete-button flex opacity-0 group-hover/tr:opacity-100">
          <Button
            color="transparentDanger"
            class="flex h-10 w-10 self-center"
            icon="trash"
            @click="showModalArchive(document)"
          >
          </Button>
        </div>
      </Tooltip>
    </template>
  </DataTable>
</template>

<style lang="postcss" scoped>
:deep(table) {
  th:first-child,
  td:first-child {
    @apply w-4 !max-w-4 px-2;
  }
}
</style>
